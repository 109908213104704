<template>
  <div>
    <a-page-header
      :title="
        $route.query.look
          ? '查看通知信息'
          : $route.query.id
          ? '修改通知'
          : '新增通知'
      "
      @back="() => $router.go(-1)"
    />
    <a-row>
      <a-col :span="17">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>
          <a-form-item label="通知类型">
            <!-- <a-select
              v-model="addFromData.content_type"
              style="width: 120px"
              @change="handleChange"
            >
              <a-select-option :value="0">
                原创
              </a-select-option>
              <a-select-option :value="1">
                关联数据
              </a-select-option>
            </a-select> -->
            <a-radio-group
              v-model="addFromData.content_type"
              @change="classChange"
              :disabled="disabledFlag"
            >
              <a-radio :value="0">
                原创
              </a-radio>
              <a-radio :value="1">
                关联数据
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item
            label="关联数据类型"
            v-show="addFromData.content_type == 1"
          >
            <!-- <a-select
              v-model="addFromData.obj_type"
              style="width: 120px"
              @change="objTypeChange"
            >
              <a-select-option :value="1">
                文章
              </a-select-option>
              <a-select-option :value="2">
                视频
              </a-select-option>
              <a-select-option :value="3">
                病例
              </a-select-option>
            </a-select> -->
            <a-radio-group
              v-model="addFromData.obj_type"
              @change="objTypeChange"
              :disabled="disabledFlag"
            >
              <a-radio :value="1">
                文章
              </a-radio>
              <a-radio :value="2">
                视频
              </a-radio>
              <a-radio :value="3">
                病例
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="关联内容" v-show="addFromData.content_type == 1">
            <a-select
              :disabled="disabledFlag"
              allowClear
              show-search
              @focus="Focus"
              label-in-value
              :value="value"
              placeholder="请输入关键字查询"
              style="width: 100%"
              :filter-option="false"
              @search="fetchUser"
              @change="handleChangeSelect"
            >
              <a-select-option v-for="d in data" :key="d.id">
                {{ addFromData.obj_type == "1" ? d.post_title : d.title }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="内容" v-show="addFromData.content_type == 0">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="6">
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="点击量">
            <a-input-number
              :min="0"
              v-model="addFromData.hits"
              style="width:195px"
            />
          </a-form-item>
          <a-form-item label="发布时间">
            <a-date-picker
              show-time
              v-model="addFromData.publish_time"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :locale="locale"
            >
            </a-date-picker>
          </a-form-item>
          <a-form-item label="是否发布">
            <a-radio-group v-model="addFromData.is_publish">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-if="$route.query.look != 1">
            <a-button
              type="primary"
              :style="{ marginLeft: '120px' }"
              @click="handleOk"
            >
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import UE from "@/components/UE/UE.vue";
import { watch } from "fs";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import {
  casesShowList,
  articleShowList,
  videoShowList,
  messageDataSave,
  messageDetail,
} from "@/apis/index";

export default {
  data() {
    return {
      locale,
      addFromData: {
        id: "",
        title: "",
        content: "",
        hits: "",
        publish_time: "",
        is_publish: 1,
        content_type: 0,
        obj_type: 1,
        obj_id: "",
      },
      value: undefined,
      data: [],
      focusFlag: true,

      disabledFlag: false,
    };
  },
  components: {
    UE,
  },
  watch: {},
  created() {
    if (this.$route.query.id) {
      messageDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData.id = res.data.id;
          this.addFromData.title = res.data.title;
          this.addFromData.hits = res.data.hits;
          this.addFromData.content_type = res.data.content_type;

          if (res.data.publish_time > 0) {
            this.addFromData.publish_time = moment(
              Number(res.data.publish_time + "000")
            ).format("YYYY-MM-DD HH:mm:ss");
            this.disabledFlag = true;
          } else {
            this.addFromData.publish_time = "";
          }

          if (res.data.content_type == 0) {
            this.addFromData.content = res.data.content;
          } else {
            this.addFromData.obj_type = res.data.obj_info.obj_type;
            this.addFromData.obj_id = res.data.obj_info.obj_id;
            this.value = {
              key: res.data.obj_info.obj_id,
              label: res.data.obj_info.title,
            };
          }
        }
      });
    }
  },
  computed: {},
  methods: {
    classChange(e) {
      if (e.target.value == 0) {
        this.addFromData.obj_id = "";
        this.value = undefined;
      } else {
        this.addFromData.content = "";
      }
    },
    objTypeChange() {
      this.value = undefined;
      this.data = [];
      this.focusFlag = true;
    },
    Focus() {
      if (this.focusFlag) {
        this.focusFlag = false;
        this.getShowList();
      }
    },
    fetchUser(value) {
      this.getShowList(value);
    },
    getShowList(keyword = "") {
      switch (this.addFromData.obj_type) {
        case 1:
          articleShowList({
            page: 1,
            limit: 50,
            keyword,
          }).then((res) => {
            if (res.code == 1) {
              this.data = res.data.list;
            }
          });
          break;
        case 2:
          videoShowList({
            page: 1,
            limit: 50,
            keyword,
          }).then((res) => {
            if (res.code == 1) {
              this.data = res.data.list;
            }
          });
          break;
        default:
          casesShowList({
            page: 1,
            limit: 50,
            keyword,
          }).then((res) => {
            if (res.code == 1) {
              this.data = res.data.list;
            }
          });
          break;
      }
    },
    handleChangeSelect(value) {
      if (value) {
        this.value = value;
        this.addFromData.obj_id = value.key;
      } else {
        this.value = undefined;
        this.addFromData.obj_id = "";
      }
    },
    handleOk() {
      messageDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.push({ path: "/systems/Notice" });
          this.$message.success("成功");
        }
      });
    },
    getUeContent(val) {
      this.addFromData.content = val;
    },
  },
};
</script>

<style lang="less" scoped></style>
